import { Component, Input, OnInit } from '@angular/core';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';

@Component({
  selector: 'app-linha-tempo-socios',
  templateUrl: './linha-tempo-socios.component.html',
  styleUrls: ['./linha-tempo-socios.component.scss']
})
export class LinhaTempoSociosComponent implements OnInit {
  panelOpenState = false;
  timeLine;
  exibeMensagemErro = false
  @Input() doc: any;

  constructor(
    private pesquisaService: PesquisaService
  ) { }

  ngOnInit(): void {
    this.getLinhaTempo();
  }

  getLinhaTempo() {
    this.exibeMensagemErro = false;
    this.pesquisaService.getLinhaTempoSocios(this.doc).subscribe(data => {
      if (data) {
        this.timeLine = this.mergeTimeline(data.atuais, data.historicos);
        console.log(this.timeLine); // Verifique o resultado mesclado
      }
    }, (err: any) => {
      this.exibeMensagemErro = true;
    });
  }
  
  /**
   * Mescla os dados de `atuais` e `historicos` em uma única estrutura
   */
  mergeTimeline(atuais: any, historicos: any[]): any[] {
    // Ordenar os anos dos históricos em ordem decrescente
    const historicosOrdenados = historicos.sort((a, b) => Number(b.ano) - Number(a.ano));
  
    // Adicionar os dados de 'atuais' ao início
    if (atuais) {
      historicosOrdenados.unshift(atuais);
    }
  
    return historicosOrdenados;
  }
  
}
