

  <mat-accordion>
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="mb-0 d-flex align-items-center">
            <mat-icon>work</mat-icon>&nbsp;
            LINHA DO TEMPO SÓCIOS
          </h3>
        </mat-panel-title>

      </mat-expansion-panel-header>
      <div class="timeline ml-5" *ngIf="!exibeMensagemErro">
        <div *ngFor="let entry of timeLine; let i = index">
          <div class="container" [ngClass]="{ left: i%2!==0, right:i%2==0}">
            <div class="content">
              <h2>{{entry.ano}}</h2>
              <div *ngFor="let detalhe of entry.detalhes">
                <p><b>Nome: </b>{{detalhe.nome}}</p>
                <p><b>Documento: </b>{{detalhe.cpfCnpj}}</p>
                <p><b>Dta Entrada: </b>{{detalhe.dataEntradaSociedade}}</p>
                <p><b>Qualificação: </b>{{detalhe.qualificacao}}</p>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" *ngIf="exibeMensagemErro">
        <p>Ocorreu um erro ao pesquisar a linha do tempo de sócios</p>
      </div>
      
    </mat-expansion-panel>
  </mat-accordion>