import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthModule } from 'src/app/@auth/auth.module';
import { ViewsModule } from 'src/app/views/views.module';
import { DadosCadastraisComponent } from './dados-cadastrais/dados-cadastrais.component';
import { LinhaTempoComponent } from './sintese-processo/linha-tempo/linha-tempo.component';
import { MidiasNegativasComponent } from './midias-negativas/midias-negativas.component';
import { SinteseProcessoComponent } from './sintese-processo/sintese-processo.component';
import { VisaoEmTelaComponent } from './visao-em-tela.component';
import { GraficoTipoLinhaComponent } from './sintese-processo/linha-tempo/grafico-tipo-linha/grafico-tipo-linha.component';
import { SinteseCreditoComponent } from './sintese-credito/sintese-credito.component';
import { LoaderOnlyComponent } from '../loader-only/loader-only.component';
import { DadosCadastraisCompletosComponent } from './dados-cadastrais/dados-cadastrais-completos/dados-cadastrais-completos.component';
import { DetechEmTelaComponent } from './detech-em-tela/detech-em-tela.component';
import { AnaliseCreditoCompletoComponent } from './sintese-credito/analise-credito-completo/analise-credito-completo.component';
import { SintesePatrimonialComponent } from './sintese-patrimonial/sintese-patrimonial.component';
import { EscrituraEProcuracoesComponent } from './escritura-e-procuracoes/escritura-e-procuracoes.component';
import { RegistradoresQualificadaComponent } from './sintese-patrimonial/registradores-qualificada/registradores-qualificada.component';
import { LinhaTempoSociosComponent } from './dados-cadastrais/linha-tempo-socios/linha-tempo-socios.component';
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
   DadosCadastraisComponent,
   LinhaTempoComponent,
   MidiasNegativasComponent,
   SinteseProcessoComponent,
   VisaoEmTelaComponent,
   GraficoTipoLinhaComponent,
   SinteseCreditoComponent,
   LoaderOnlyComponent,
   DadosCadastraisCompletosComponent,
   DetechEmTelaComponent,
   AnaliseCreditoCompletoComponent,
   SintesePatrimonialComponent,
   EscrituraEProcuracoesComponent,
   RegistradoresQualificadaComponent,
   LinhaTempoSociosComponent
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule, 
    FormsModule,
    AppRoutingModule,
    AuthModule.forRoot(),
  ],
  exports:[
    DadosCadastraisComponent,
    LinhaTempoComponent,
    MidiasNegativasComponent,
    EscrituraEProcuracoesComponent,    
    SinteseProcessoComponent,
    VisaoEmTelaComponent,
    GraficoTipoLinhaComponent,
    SinteseCreditoComponent,
    LoaderOnlyComponent
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' },DecimalPipe],
})
export class VisaoTelaModule { }
