<div class="text-center p-3 header">Solicitação de pesquisa {{produto}}</div>
<div *ngIf="!isLote">
  <div class="text-center mensagem-qualificada" *ngIf="exibirMensagemQualificada()">
    <p class="p-0 mb-0">Sua solicitação contém pesquisas qualificadas que podem demorar até 7 dias</p>
    <p class="p-0 mb-0">*Os cartórios podem não responder ou até demorar mais do que o esperado</p>
  </div>
  <div class="mt-5 content-values">

    <hr class="hr-values" *ngIf="fontesPreviasSelecionada.length > 0">
    <h3 class="title-tipo-pesquisa mt-1" *ngIf="fontesPreviasSelecionada.length > 0">Pesquisa Prévia</h3>
    <div class="" *ngFor="let adicionais of fontesPreviasSelecionada">
      <div class="parametrosAdicionais d-flex justify-content-between mt-2" *ngIf="fontesPreviasSelecionada.length > 0">
        <p class="textConfirm ml-2">{{adicionais.mensagem}}</p>
        <p class="textConfirm">
          R${{adicionais?.valor !== undefined ? adicionais.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <!-- <hr class="hr-values" *ngIf="fontesPreviasSelecionada.length > 0"> -->
    </div>

    <hr class="hr-values" *ngIf="fontesQualificadasSelecionada?.length > 0">
    <h3 class="title-tipo-pesquisa" *ngIf="fontesQualificadasSelecionada?.length > 0">Pesquisa Qualificada</h3>
    <div class="" *ngFor=" let qualificada of fontesQualificadasSelecionada">
      <div class="fontesAdicionais d-flex justify-content-between" *ngIf="fontesQualificadasSelecionada?.length > 0">
        <p class="textConfirm ml-2">{{qualificada.mensagem}}</p>
        <p class="textConfirm">
          R${{qualificada?.valor !== undefined ? qualificada.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <div class="fontesAdicionais d-flex justify-content-between" *ngFor="let cidade of qualificada.cidades">
        <p class="textConfirmCity ml-5">{{cidade.mensagem}}</p>
        <p class="textConfirmCity">
          R${{cidade?.valor !== undefined ? cidade.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <!-- <hr class="hr-values" *ngIf="fontesQualificadasSelecionada?.length > 0"> -->
    </div>

    <hr class="hr-values" *ngIf="data?.dados?.fontesAdicionaisSelecionadas?.length > 0">
    <h3 class="title-tipo-pesquisa" *ngIf="data?.dados?.fontesAdicionaisSelecionadas?.length > 0">Adicionais</h3>
    <div *ngFor="let adicionais of data?.dados?.fontesAdicionaisSelecionadas">
      <div class="fontesAdicionais d-flex justify-content-between">
        <p class="textConfirm ml-2">{{adicionais.descricao}}</p>
        <p class="textConfirm">
          R${{adicionais?.valor !== undefined ? adicionais.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <!-- <hr class="hr-values"> -->
    </div>

    <hr class="hr-values" *ngIf="parametrosAvulsos?.length > 0">
    <h3 class="title-tipo-pesquisa" *ngIf="parametrosAvulsos?.length > 0">Parâmetros</h3>
    <div *ngFor="let parametros of parametrosAvulsos">
      <div class="fontesAdicionais d-flex justify-content-between">
        <p class="textConfirm ml-2">{{parametros.mensagem}}</p>
        <p class="textConfirm">
          R${{parametros?.valor !== undefined ? parametros.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <!-- <hr class="hr-values"> -->
    </div>


    <div class="d-flex justify-content-between">
      <p class="valorAzul">TOTAL DE CONSUMO DE CRÉDITOS</p>
      <p class="valorAzul">R${{(data.dados?.valorPesquisaAdicional + data.dados?.valorPesquisaParametro).toFixed(2)}}
      </p>
    </div>
  </div>
</div>

<div *ngIf="isLote">
  <div class="text-center mensagem-qualificada" *ngIf="exibirMensagemQualificadaLote()">
    <p class="p-0 mb-0">Sua solicitação contém pesquisas qualificadas que podem demorar até 7 dias</p>
    <p class="p-0 mb-0">*Os cartórios podem não responder ou até demorar mais do que o esperado</p>
  </div>
  <div class="mt-5 content-values">

    <h3 class="title-tipo-pesquisa mt-1" *ngIf="fontesPreviasSelecionada?.length > 0">Pesquisa Prévia</h3>
    <hr class="hr-values" *ngIf="data.somaValores?.valorPesquisaAdicional > 0">
    <div class="" *ngFor=" let adicionais of fontesPreviasSelecionada">
      <div class="parametrosAdicionais d-flex justify-content-between mt-2"
        *ngIf="fontesPreviasSelecionada?.length > 0">
        <p class="textConfirm ml-2">{{adicionais.mensagem}} (x{{ data?.quantidadeDocs }})</p>
        <p class="textConfirm">
          R${{adicionais?.valor !== undefined ? adicionais.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <hr class="hr-values" *ngIf="fontesPreviasSelecionada?.length > 0">
    </div>

    <h3 class="title-tipo-pesquisa" *ngIf="fontesQualificadasSelecionada?.length > 0">Pesquisa Qualificada
    </h3>
    <hr class="hr-values" *ngIf="fontesQualificadasSelecionada?.length > 0">
    <div class="" *ngFor=" let qualificada of fontesQualificadasSelecionada">
      <div class="fontesAdicionais d-flex justify-content-between"
        *ngIf="fontesQualificadasSelecionada?.length > 0">
        <p class="textConfirm ml-2">{{qualificada.mensagem}} (x{{ data?.quantidadeDocs }})</p>
        <p class="textConfirm">
          R${{qualificada?.valor !== undefined ? qualificada.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <div class="fontesAdicionais d-flex justify-content-between" *ngFor="let cidade of qualificada.cidades">
        <p class="textConfirmCity ml-5">{{cidade.mensagem}}</p>
        <p class="textConfirmCity">
          R${{cidade?.valor !== undefined ? cidade.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <hr class="hr-values" *ngIf="fontesQualificadasSelecionada?.length > 0">
    </div>

    <hr class="hr-values" *ngIf="data?.somaValores?.fontesAdicionaisSelecionadas?.length > 0">
    <h3 class="title-tipo-pesquisa" *ngIf="data?.somaValores?.fontesAdicionaisSelecionadas?.length > 0">Adicionais</h3>
    <div *ngFor="let adicionais of data?.somaValores?.fontesAdicionaisSelecionadas">
      <div class="fontesAdicionais d-flex justify-content-between">
        <p class="textConfirm ml-2">{{adicionais.descricao}}  (x{{ data?.quantidadeDocs }})</p>
        <p class="textConfirm">
          R${{adicionais?.valor !== undefined ? adicionais.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <!-- <hr class="hr-values"> -->
    </div>

    <h3 class="title-tipo-pesquisa" *ngIf="parametrosAvulsos?.length > 0">Parâmetros</h3>
    <hr class="hr-values" *ngIf="parametrosAvulsos?.length > 0">
    <div *ngFor="let parametros of parametrosAvulsos">
      <div class="fontesAdicionais d-flex justify-content-between">
        <p class="textConfirm ml-2">{{parametros.mensagem}}</p>
        <p class="textConfirm">
          R${{parametros?.valor !== undefined ? parametros.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <hr class="hr-values">
    </div>


    <div class="d-flex justify-content-between">
      <p class="valorAzul">TOTAL DE CONSUMO DE CRÉDITOS</p>
      <p class="valorAzul">R${{data.somaValores?.valorAdicional + data.somaValores?.valorParametro}}</p>
    </div>
  </div>
</div>

<div class="mt-1">
  <div class="d-flex justify-content-between mt-3">
    <button class="btn btn-primary btn-cancel" mat-dialog-close (click)="fecharModal()">Cancelar</button>
    <button class="btn btn-primary btn-go" (click)="realizarPesquisa()">Confirmar</button>
  </div>
</div>
