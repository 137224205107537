import { PopcreditoComponent } from './../../components/view/popcredito/popcredito.component';
import { NavService } from './../../components/template/nav/nav.service';
import { HeaderService } from './../../components/template/header/header.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioService } from '../../shared/services/http/usuario.service';
import { UiService } from '../../shared/services/ui.service';
import { forkJoin } from 'rxjs';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import * as ApexCharts from 'apexcharts';
import { DashboardService } from 'src/app/shared/services/dashboard/dashboard-service';
import { ContratoClienteComponent } from './contrato-cliente/contrato-cliente.component';
import { TabelaComponent } from './tabela/tabela.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  constructor(private usuarioService: UsuarioService,
    private authService : AuthService,
    private ui: UiService,
    private dashoboardService: DashboardService,
    private toastr: ToastrService,
    private headerService: HeaderService,
    private navService: NavService,
    public dialog: MatDialog,
    private _decimalPipe: DecimalPipe) {
      navService.navData = {
        title:'Dashboard'
      }
      headerService.headerData = {
      title:'Dashboard'
    }
  }

  openDialog() {
    this.dialog.open(PopcreditoComponent);
  }

  basicBarChart: any = {};
  inicializeChart: any = {};

  mensagemErro: any;
  usuario: any;
  idCliente = true;
  dashboard: any = {};
  pipe = new DatePipe('en-US');
  nomeEmpresa: string = "N/I";
  numeroIdCliente;
  nomeDoPacote: string = "N/I";
  qtdeUsuarios: any = 0;
  ultimoAcesso: any = this.pipe.transform(new Date(), 'dd/MM/yyyy HH:mm');
  pesquisaEmAndamento: any = 0;
  pesquisasRealizadas: any = 0;
  percentualPacote: any = 0;
  totalCadastro: any = 0;
  totalCertidoes: any = 0;
  totalProcesso: any = 0;
  totalPatrimonial: any = 0;
  totalCredito: any = 0;
  totalDossie: any = 0;
  totalLocalizador: any = 0;
  totalDetech: any = 0;
  toggle = true;
  documentoAnexado;
  nomeAnexo
  displayUso: boolean = false;
  displayVisao: boolean = false;
  displayConsultas: boolean = true;
  exibeRelatorio: boolean = false;
  exibeSaldo: boolean = false;

  processosChart;

  docsPesquisadosMes;

  analiseCredito;

  ngOnInit(): void {
    this.ui.loading();

    this.authService.callbackUsuario().subscribe(usuario => {
      if(!usuario) {
        return;
      }
      this.usuario = usuario;

      console.log(this.usuario)


      let requests = [
        this.usuarioService.getDashboard(this.usuario.id)
      ];

      forkJoin(requests).subscribe(data => {
        this.ui.loaded().subscribe(() => {
          this.dashboard = data[0];
          console.log(data)
          this.dashboard.usuario = usuario;
          this.analiseCredito = data[0].analiseCredito;
          this.processosChart = data[0].qntProcessos;
          this.docsPesquisadosMes = data[0].docsPesquisadosMes;


          this.nomeEmpresa = usuario.cliente.nome;
          this.nomeEmpresa = this.nomeEmpresa.split(" ")[0];
          this.numeroIdCliente = usuario.cliente.id
          console.log(usuario)
          if(usuario.cliente.id == 52 || usuario.cliente.cnpj == "01402265000189"){
            this.idCliente = true
          }
          else{
            this.idCliente = false
            this.displayUso = true
          }


          this.nomeDoPacote = this.dashboard.nomePacote;

          this.qtdeUsuarios = this.dashboard.usuarios;

          this.pesquisaEmAndamento = this.dashboard.andamento;
          this.pesquisasRealizadas = this.dashboard.pesquisas;

          if(this.dashboard.porcentagem < 1)
            this.percentualPacote = this._decimalPipe.transform(this.dashboard.porcentagem,"1.1-1");
          else
            this.percentualPacote = this._decimalPipe.transform(this.dashboard.porcentagem,"1.0-0");

          //this.dashboard.usuario.ultimoAcesso = this.pipe.transform(this.dashboard.usuario.ultimoAcesso, 'dd/MM/yyyy HH:mm:ss');
          this.ultimoAcesso = this.pipe.transform(this.dashboard.usuario.ultimoAcesso, 'dd/MM/yyyy HH:mm');

          this.totalCadastro = this.dashboard.cadastros;
          this.totalProcesso = this.dashboard.processos;
          this.totalPatrimonial = this.dashboard.patrimonial;
          this.totalCredito = this.dashboard.credito;
          this.totalDossie = this.dashboard.completo;
          this.totalLocalizador = this.dashboard.localizador;
          this.totalDetech = this.dashboard.detech;
          this.totalCertidoes = this.dashboard.certidoes;



          setTimeout(() => {
            this.charts();
            this.circleChart();
            this.chartsLine();
            this.chartPizza();
            this.barChart()
           }, 300);

        });
      });
    });
    this.corClicked()
  }

  enableDisableRule(job) {
    this.toggle = !this.toggle;
  }

  clickRelatorio() : void {
    this.toastr.warning("Relatório em Desenvolvimento", "Portal Credvalue")
  };


 clickTabela() : void {
  this.dialog.open(TabelaComponent, {
    width: '60%',
  });
};

//   clickContrato() : void {
//   this.toastr.warning("Contrato em Desenvolvimento", "Portal Credvalue")
// };

  clickContato() : void {
    window.open("mailto:contato@credvalue.com.br", "_blank")
}

  circleChart(){
    var options = {
      chart: {
        height: 400,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%"
          },
          track: {
            background: '#c6c7c7',
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              color: "#888",
              fontSize: "13px"
            },
            value: {
              color: "#0DA6D9",
              fontSize: "3rem",
              fontWeight: 900,
              letterSpacing: "-2px",
              show: true
            }
          }
        },
      },
      title: {
        text: 'Uso do Pacote ',
        align: 'center',
        style: {
          fontSize: 16,
          color: '#33a3d9',
          fontWeight: 400
        }
      },
      stroke: {
        lineCap: "round",
      },
      series: [this.percentualPacote],
    }
    var chart = new ApexCharts(document.querySelector("#chart2"), options);
    chart.render();

    if(this.usuario.permissoes.includes("dashboard_saldo")){
      setTimeout(() => {
        const valorSaldo =`Saldo disponível R$ ${this.dashboard.saldoAtual}`;
        var valueText = document.createElement('div');
        valueText.innerText = valorSaldo;
        valueText.style.fontSize = '20px';
        valueText.style.color = '#0DA6D9';
        valueText.style.fontWeight = '500',
        valueText.style.textAlign = 'center';
        valueText.style.marginTop = '-25px';
        document.querySelector("#chart2").appendChild(valueText);
      }, 1000);
    }
    //indow.dispatchEvent(new Event('resize'))
  }
  
  private charts(){
    var options = {
      chart: {
        width: "75%",
        height: 380,
        type: "bar",
        color: "#fff",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      title: {
        text: 'Uso por Produto ',
        align: 'center',
        style: {
          fontSize: 16,
          color: '#33a3d9',
          fontWeight: 400
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      series: [
        {
          data: [this.totalCadastro, this.totalProcesso, this.totalPatrimonial, this.totalCredito, this.totalDossie, this.totalCertidoes, this.totalLocalizador, this.totalDetech],
          name: ""
        },
    
      ],
      xaxis: {
        categories: ['Informações Cad.', 'Pesq. Processos', 'Pesquisa Patr.', 'Análise de Crédito', 'Dossiê Completo', 'Certidões', 'Localizador', 'Detech'],
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
            style: {
              colors: '#1e6b85',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
        }
      },
      legend: {
        position: "right",
        verticalAlign: "top",
        containerMargin: {
          left: 35,
          right: 60
        }
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            plotOptions: {
              bar: {
                horizontal: false
              }
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };

    var chart = new ApexCharts(
      document.querySelector("#responsive-chart"),
      options
    );
    
    chart.render();
    window.dispatchEvent(new Event('resize'))
  }

  private chartsLine(){
    var optionsLine = {
      series: [
        {
          name: "CNPJ",
          data: this.docsPesquisadosMes.qntCnpj
        },
        {
          name: "CPF",
          data: this.docsPesquisadosMes.qntCpf
        }
      ],
      chart: {
        height: 350,
        width: "75%",
        type: "line",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: 'Documentos pesquisados por mês',
        align: 'center',
        style: {
          fontSize: 16,
          color: '#33a3d9',
          fontWeight: 400
        }
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5 
        }
      },
      xaxis: {
        categories: this.docsPesquisadosMes.mesesReferencia
      }
    };

    var chart = new ApexCharts(
      document.querySelector("#lineChart"),
      optionsLine
    );
    
    chart.render();
    window.dispatchEvent(new Event('resize'))
  }

  private chartPizza(){
    var optionsPizza = {
      series: [this.processosChart.ativos, this.processosChart.passivos],
      chart: {
        width: 380,
        type: "pie"
      },
      legend: {
        position: "bottom"
      },
      labels: ["Polo Ativo", "Polo Passivo"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };

    var chart = new ApexCharts(
      document.querySelector("#pizzaChart"),
      optionsPizza
    );
    
    chart.render();
    window.dispatchEvent(new Event('resize'))
  }

  private barChart(){
    var optionsBar = {
      series: [
        {
          name: "",
          data: [this.analiseCredito.inadimplentes, this.analiseCredito.bonsPagadores]
        }
      ],
      chart: {
        type: "bar",
        height: 500
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [
          "Inadimplentes",
          "Bons Pagadores",
        ]
      }
    };

    var chart = new ApexCharts(
      document.querySelector("#barChart"),
      optionsBar
    );
    
    chart.render();
    window.dispatchEvent(new Event('resize'))
  }

  renderCharts(){
    if(!this.dashboard){
      return 'd-none'
    }
    setTimeout(() => {
      return ''
     }, 300);
  }

  changeDisplayConsultas(){
    this.displayVisao = false
    this.displayConsultas = true
    this.displayUso = false
  }

  changeDisplayVisao(){
    this.displayVisao = true
    this.displayConsultas = false
    this.displayUso = false
  }
  changeDisplayUso(){
    this.numeroIdCliente = this.usuario.cliente.id;
    this.displayVisao = false
    this.displayUso = true
    this.displayConsultas = false
  }

  exibirRelatorio(){
    this.exibeRelatorio = true
  }

  corClicked(){
    document.getElementById('1').setAttribute("style", "color:#0070c0; font-size: 22px");
    document.getElementById('2').setAttribute("style", "color:#b6b5b5; border-color: #b6b5b5; font-size: 19px");
    document.getElementById('4').setAttribute("style", "color:#b6b5b5; border-color: #b6b5b5; font-size: 19px");
  }

  corClicked2(){
    document.getElementById('2').setAttribute("style", "color:#0070c0; font-size: 22px");
    document.getElementById('3').setAttribute("style", "color:#b6b5b5; border-color: #b6b5b5; font-size: 19px");
    document.getElementById('1').setAttribute("style", "color:#b6b5b5; border-color: #b6b5b5; font-size: 19px");
    document.getElementById('4').setAttribute("style", "color:#b6b5b5; border-color: #b6b5b5; font-size: 19px");
  }

  corClicked3(){
    document.getElementById('4').setAttribute("style", "color:#0070c0; font-size: 22px");
    document.getElementById('3').setAttribute("style", "color:#b6b5b5; border-color: #b6b5b5; font-size: 19px");
    document.getElementById('3').setAttribute("style", "color:#b6b5b5; border-color: #b6b5b5; font-size: 19px");
    document.getElementById('1').setAttribute("style", "color:#b6b5b5; border-color: #b6b5b5; font-size: 19px");
  }

  closeComponent(){
    this.exibeRelatorio = false
  }

  clickContrato() {
    this.dialog.open(ContratoClienteComponent, {
        hasBackdrop: true,
        data: {
          idCliente: this.usuario.cliente.id,
        }
    });
}

habilitaTabela() {
  return this.usuario.permissoes.includes("dashboard-tabela");
}


habilitaRelatorios() {
  return this.usuario.permissoes.includes("dashboard-relatorios");
}

habilitaContrato() {
  return this.usuario.permissoes.includes("dashboard-ver-contrato");
}

habilitaContatoSuporte() {
  return this.usuario.permissoes.includes("dashboard-contato-suporte");
}

}
