<div class="container">
  <div class="content-box">
    <h3 class="identificador-title text-center">Resultado do Cruzamento de Dados</h3>
    <div class="row m-2" *ngIf="dadosAba">
      <div class="col col-grey">
        <p class="pt-2"><b>Identificação:</b> {{dadosAba?.idpesquisa?.identificacao}}</p>
      </div>

      <div class="col col-grey">
        <p class="pt-2"><b>Emitido por:</b> {{dadosAba?.idpesquisa?.usuarioCadastro}}</p>
      </div>

      <div class="col col-grey">
        <p class="pt-2"><b>Data:</b> {{dadosAba?.idpesquisa?.data | date: 'dd/MM/yyyy HH:mm' }}</p>
      </div>
      <div class="row" *ngIf="dadosAba">
        <div class="col col-grey">
          <p class="pt-2"><b>Total Emails:</b> {{getTotalEmails()}}</p>
        </div>

        <div class="col col-grey">
          <p class="pt-2"><b>Total Telefones:</b> {{getTotalTelefones()}}</p>
        </div>

        <div class="col col-grey">
          <p class="pt-2"><b>Total Endereços:</b> {{getTotalEnderecos()}}</p>
        </div>

        <div class="col col-grey">
          <p class="pt-2"><b>Total Processos:</b> {{getTotalProcessosAtivos() + getTotalProcessosPassivos() }}</p>
        </div>
      </div>
    </div>
  </div>

  <mat-accordion>
    <mat-expansion-panel class="py-2 mt-3" [expanded]="true" (opened)="panelOpenState = true"
      (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="mb-0 d-flex align-items-center">
            TOTAL DE DOCUMENTOS PARA CRUZAMENTO {{ infoPesquisa?.length }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <loader-only *ngIf="loadingOrigem"></loader-only>
      <div style="width: 100%;" class="content-box">
        <table class="table w-100" *ngIf="!loadingOrigem && dadosOrigemPesquisa?.length > 0">
          <thead>
            <tr class="text-center">
              <th colspan="6" class="thead-top-only p-2">
                Documentos Escolhidos Para o Cruzamento de Dados
              </th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td colspan="6">
                  <div class="w-100">
                    <mat-tab-group class="w-100" animationDuration="0ms">
                      <mat-tab label="Geral" [class]="'tab-cruzamento'">
                        <table class="table w-100">
                          <tbody *ngFor="let info of dadosOrigemPesquisa">
                            <tr class="table-info text-center">
                              <td>
                                <div class="cell-flex">
                                  <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                                  <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                                  <span class="plus-data" *ngIf="info?.documentos?.length > 1"
                                    (click)="openModalDados(info, 5)">{{info?.documentos?.length}}</span>
                                </div>
                              </td>
                              <td class="limited-text">{{ info?.nome }}</td>
                              <td>
                                <div class="cell-flex">
                                  <span>Endereços</span>
                                  <span class="plus-data" *ngIf="info?.enderecos?.length > 0"
                                    (click)="openModalDados(info, 1)">
                                    {{ info?.enderecos.length }}</span>
                                </div>
                              </td>
                              <td>
                                <div class="cell-flex">
                                  <span>Telefones</span>
                                  <span class="plus-data" *ngIf="info?.telefones?.length > 0"
                                    (click)="openModalDados(info, 3)">
                                    {{ info?.telefones.length }}</span>
                                </div>
                              </td>
                              <td>
                                <div class="cell-flex">
                                  <span>Emails</span>
                                  <span class="plus-data" *ngIf="info?.emails?.length > 0"
                                    (click)="openModalDados(info, 2)">
                                    {{ info?.emails.length }}</span>
                                </div>
                              </td>
                              <td>
                                <div class="cell-flex">
                                  <span>Processos</span>
                                  <span class="plus-data"
                                    *ngIf="info?.processos.processosAtivo?.length > 0 || info?.processos.processosPassivo.length > 0"
                                    (click)="openModalDados(info, 4)">
                                    {{ info?.processos.processosAtivo.length + info?.processos.processosPassivo.length }}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </mat-tab>
    
                      <mat-tab label="Endereços" class="tab-cruzamento">
                        <table class="table w-100">
                          <ng-container *ngFor="let info of dadosOrigemPesquisa">
                            <tr class="table-info text-center " *ngIf="info?.enderecos?.length > 0">
                              <td>
                                <div class="text-center bg-none">
                                  <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                                  <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                                </div>
                              </td>
                              <td class="limited-text">{{ info?.nome }}</td>
                              <td>
                                <div class="cell-flex text-center bg-none" *ngFor="let endereco of info?.enderecos">
                                  <span>{{ endereco ? endereco : '-' }}</span>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </table>
                      </mat-tab>
    
                      <mat-tab label="Telefones" class="tab-cruzamento">
                        <table class="table w-100">
                          <ng-container *ngFor="let info of dadosOrigemPesquisa">
                            <tr class="table-info text-center" *ngIf="info?.telefones?.length > 0">
                              <td>
                                <div class="text-center bg-none">
                                  <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                                  <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                                </div>
                              </td>
                              <td class="limited-text">{{ info?.nome }}</td>
                              <td>
                                <div class=" text-center bg-none" *ngFor="let telefone of info?.telefones">
                                  <span>{{ telefone ? telefone : '-' }}</span>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </table>
                      </mat-tab>
    
    
                      <mat-tab label="Emails" class="tab-cruzamento">
                        <table class="table w-100">
                          <ng-container *ngFor="let info of dadosOrigemPesquisa">
                            <tr class="table-info text-center" *ngIf="info?.emails?.length > 0">
                              <td>
                                <div class="text-center bg-none">
                                  <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                                  <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                                </div>
                              </td>
                              <td class="limited-text">{{ info?.nome }}</td>
                              <td>
                                <div class="cell-flex text-center bg-none" *ngFor="let mail of info?.emails">
                                  <span>{{ mail ? mail : '-' }}</span>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </table>
                      </mat-tab>
    
    
                      <mat-tab label="Processos">
                        <table class="table w-100">
                          <ng-container *ngFor="let info of dadosOrigemPesquisa">
                            <tr class="table-info text-center" *ngIf="info?.processos?.processosAtivo?.length > 0 || info?.processos?.processosPassivo?.length > 0">
                              <td>
                                <div class="text-center bg-none">
                                  <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                                  <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                                </div>
                              </td>
                              <td class="limited-text">{{ info?.nome }}</td>
                              <td>
                                <div class="text-center bg-none">
                                  <div class="scroll-processos">
                                    <span *ngIf="info?.processos?.processosAtivo?.length > 0">
                                      <b>Ativos:</b> {{ info?.processos?.processosAtivo.join(', ') }}
                                    </span>
                                  </div>
                                  <span *ngIf="info?.processos?.processosAtivo?.length > 0 && info?.processos?.processosPassivo?.length > 0"> | </span>
                                  <div class="scroll-processos">
                                    <span *ngIf="info?.processos?.processosPassivo?.length > 0">
                                      <b>Passivos:</b> {{ info?.processos?.processosPassivo.join(', ') }}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </table>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </td>
              </tr>
            </tbody>
        </table>
      </div>

      <mat-paginator *ngIf="totalItemsOrigem > 15" [length]="totalItemsOrigem" [pageSize]="pageSizeOrigem"
        [pageIndex]="pageIndexOrigem" (page)="changePageOrigem($event)">
      </mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel class="py-2 mt-3" [expanded]="true" (opened)="panelOpenState = true"
      (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="mb-0 d-flex align-items-center">
            DADOS CRUZADOS E COMPARTILHADOS
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-cruzamanto-filtro [dadosAba]="dadosAba" (dadosCruzadosEvent)="onDadosCruzados()"></app-cruzamanto-filtro>

      <loader-only *ngIf="isLoadingDados"></loader-only>
      <table class="table w-100 mt-4" *ngIf="!isLoadingDados && dadosCruzados?.length > 0">
        <thead class="thead-light">
          <tr class="text-center">
            <th colspan="6" class="thead-top-only p-2">
              Pessoas e Empresas que compartilham mesmos dados de endereços, telefones, emails e processos
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="6">
              <div class="w-100">
                <mat-tab-group class="w-100" animationDuration="0ms">
                  <mat-tab label="Geral" [class]="'tab-cruzamento'">
                    <table class="table w-100">
                      <tbody *ngFor="let info of dadosPaginados">
                        <tr class="table-info text-center">
                          <td>
                            <div class="cell-flex">
                              <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                              <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                              <span class="plus-data" *ngIf="info?.documentos?.length > 1"
                                (click)="openModalDados(info, 5)">{{info?.documentos?.length}}</span>
                            </div>
                          </td>
                          <td class="limited-text">{{ info?.nome }}</td>
                          <td>
                            <div class="cell-flex">
                              <span>Endereços</span>
                              <span class="plus-data" *ngIf="info?.enderecos?.length > 0"
                                (click)="openModalDados(info, 1)">
                                {{ info?.enderecos.length }}</span>
                            </div>
                          </td>
                          <td>
                            <div class="cell-flex">
                              <span>Telefones</span>
                              <span class="plus-data" *ngIf="info?.telefones?.length > 0"
                                (click)="openModalDados(info, 3)">
                                {{ info?.telefones.length }}</span>
                            </div>
                          </td>
                          <td>
                            <div class="cell-flex">
                              <span>Emails</span>
                              <span class="plus-data" *ngIf="info?.emails?.length > 0"
                                (click)="openModalDados(info, 2)">
                                {{ info?.emails.length }}</span>
                            </div>
                          </td>
                          <td>
                            <div class="cell-flex">
                              <span>Processos</span>
                              <span class="plus-data"
                                *ngIf="info?.processos.processosAtivo?.length > 0 || info?.processos.processosPassivo.length > 0"
                                (click)="openModalDados(info, 4)">
                                {{ info?.processos.processosAtivo.length + info?.processos.processosPassivo.length }}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </mat-tab>

                  <mat-tab label="Endereços" class="tab-cruzamento">
                    <table class="table w-100">
                      <ng-container *ngFor="let info of dadosPaginados">
                        <tr class="table-info text-center " *ngIf="info?.enderecos?.length > 0">
                          <td>
                            <div class="text-center bg-none">
                              <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                              <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                            </div>
                          </td>
                          <td class="limited-text">{{ info?.nome }}</td>
                          <td>
                            <div class="cell-flex text-center bg-none" *ngFor="let endereco of info?.enderecos">
                              <span>{{ endereco ? endereco : '-' }}</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </table>
                  </mat-tab>

                  <mat-tab label="Telefones" class="tab-cruzamento">
                    <table class="table w-100">
                      <ng-container *ngFor="let info of dadosPaginados">
                        <tr class="table-info text-center" *ngIf="info?.telefones?.length > 0">
                          <td>
                            <div class="text-center bg-none">
                              <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                              <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                            </div>
                          </td>
                          <td class="limited-text">{{ info?.nome }}</td>
                          <td>
                            <div class=" text-center bg-none" *ngFor="let telefone of info?.telefones">
                              <span>{{ telefone ? telefone : '-' }}</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </table>
                  </mat-tab>


                  <mat-tab label="Emails" class="tab-cruzamento">
                    <table class="table w-100">
                      <ng-container *ngFor="let info of dadosPaginados">
                        <tr class="table-info text-center" *ngIf="info?.emails?.length > 0">
                          <td>
                            <div class="text-center bg-none">
                              <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                              <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                            </div>
                          </td>
                          <td class="limited-text">{{ info?.nome }}</td>
                          <td>
                            <div class="cell-flex text-center bg-none" *ngFor="let mail of info?.emails">
                              <span>{{ mail ? mail : '-' }}</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </table>
                  </mat-tab>


                  <mat-tab label="Processos">
                    <table class="table w-100">
                      <ng-container *ngFor="let info of dadosPaginados">
                        <tr class="table-info text-center" *ngIf="info?.processos?.processosAtivo?.length > 0 || info?.processos?.processosPassivo?.length > 0">
                          <td>
                            <div class="text-center bg-none">
                              <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                              <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                            </div>
                          </td>
                          <td class="limited-text">{{ info?.nome }}</td>
                          <td>
                            <div class="text-center bg-none">
                              <div class="scroll-processos">
                                <span *ngIf="info?.processos?.processosAtivo?.length > 0">
                                  <b>Ativos:</b> {{ info?.processos?.processosAtivo.join(', ') }}
                                </span>
                              </div>
                              <span *ngIf="info?.processos?.processosAtivo?.length > 0 && info?.processos?.processosPassivo?.length > 0"> | </span>
                              <div class="scroll-processos">
                                <span *ngIf="info?.processos?.processosPassivo?.length > 0">
                                  <b>Passivos:</b> {{ info?.processos?.processosPassivo.join(', ') }}
                                </span>
                              </div>

                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </table>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </td>
          </tr>
        </tbody>
      </table>


      <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="changePage($event)">
      </mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>


</div>
